import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
  GoogleOAuthProvider, 
  GoogleLogin,
  useGoogleLogin 
} from '@react-oauth/google';
import Navbar from './include/Navbar';
import { useDispatch } from 'react-redux';
import { login } from '../features/user';
import { Button, Snackbar, Alert } from "@mui/material"; // Import Snackbar and Alert
import '../styles/Login.css'
import Cookies from 'js-cookie';

const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [hover, setHover] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // Snackbar state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = e => {
    e.preventDefault();
    dispatch(login(formData))
      .then(data => {
        if (data.meta.requestStatus === 'rejected') {
          const errors = Object.values(data.payload).flat();
          errors.forEach(error => {
            showSnackbar(error, 'error'); // Show snackbar for each error
          });
        } else {
          showSnackbar('Login successful', 'success');
          navigate("/");
        }
      })
      .catch(error => {
        console.error('Login error:', error);
      });
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const googleToken = response.credential;
      const res = await fetch(`${process.env.REACT_APP_API_URL}api/users/google-login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: googleToken })
      });
  
      const data = await res.json();
      if (data.access) {
        showSnackbar('Google Login successful', 'success');
        Cookies.set('access_token', data.access, { expires: 7, secure: true });
        Cookies.set('refresh_token', data.refresh, { expires: 7, secure: true });
        navigate("/");
      } else {
        showSnackbar(data.message || 'Google Login failed', 'error');
      }
    } catch (error) {
      console.error('Google login error:', error);
      showSnackbar('An error occurred during Google login', 'error');
    }
  };
  
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  var url = process.env.PUBLIC_URL;

  return (
    <div className="login-container">
      <div className="login-title">Entrar na plataforma</div>
      <div className="login-card">
        <div
          className="login-image"
          style={{
            backgroundImage: hover
              ? `url('${url}/map_login.svg')`
              : `url('${url}/map_login2.svg')`
          }}
        ></div>
        <div className="login-form-container">
          <form onSubmit={onSubmit}>
            <div className="input-field">
              <img src={`${url}/icon_login.svg`} alt="Username Icon" />
              <input
                type='text'
                name='username'
                id='username'
                placeholder=' Usuário'
                onChange={onChange}
              />
            </div>

            <div className='input-field'>
              <img src={`${url}/icon_password.svg`} alt="Password Icon" />
              <input
                type='password'
                name='password'
                id='password'
                placeholder=' Senha'
                onChange={onChange}
              />
            </div>

            <div className="login-links">
              <a href='/register'>Crie sua conta!</a>
              <a href='/reset'>Esqueceu a senha?</a>
            </div>

            <button
              type='submit'
              className='login-button'
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              Entrar
            </button>
            <div className="google-btn-div">
              <GoogleOAuthProvider 
                clientId="960109641420-oed8dnj2mrmbputovidmk6090j5rdih5.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={() => console.error('Google Login Failed')}
                  useOneTap
                />
              </GoogleOAuthProvider>
            </div>
          </form>
        </div>
      </div>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate("/")}
        style={{ marginTop: '16px' }}
      >
        Go to Homepage
      </Button>
      
      {/* Snackbar for feedback messages */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={5000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
