import './Loading.css'; 


export const loadingPage = ()=>{
    return(
    <div className="loading-container">
        <div className="spinner"></div>
            <p>Loading...</p>
    </div>
    )
} 
    